import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import Context from '../utils/Context'
import Button from './form/Button'
import FormFieldInput from './form/FormFieldInput'
import Modal from './Modal'
import { toastMessage } from './toastMessage'

export default function ManageDiaperFundModal({ open, children, onClose }: { open?: boolean; children?: (fn: () => void) => JSX.Element; onClose?: () => void }) {
  const router = useRouter()

  useEffect(() => {
    if (router.query.email) {
      setModalShown(true)
      reset({ email: router.query.email.toString() })
    }
  }, [router.query.email])
  const [modalShown, setModalShown] = useState(open || false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({})

  const context = useContext(Context)

  const { mutateAsync: findDiaperBankMutation, isLoading } = useMutation((data: { email: string }) =>
    context.apiClient.get<{ success: boolean }>(`diaperBanks?email=${encodeURIComponent(data.email)}`),
  )

  const onSubmit = async ({ email }: { email: string }) => {
    const response = await findDiaperBankMutation({ email })
    if (response.data.success) {
      router.push('/', { query: '' }, {})
      toastMessage({
        title: 'Check your inbox',
        body: 'We sent you an email to access your account. Remember to check your spam folder as sometimes these emails end up there.',
        type: 'success',
      })
      setModalShown(false)
      reset()
    } else {
      toastMessage({
        title: 'Oops!',
        body: `We couldn't find an account matching your email. Try with a different email`,
        type: 'error',
      })
    }
  }

  const close = () => {
    setModalShown(false)
    onClose?.()
  }

  return (
    <div>
      <Modal open={modalShown} onClose={close} title='Manage Account'>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='font-light text-md mb-8'>Please enter your email address associated with your account.</div>
            <FormFieldInput type='email' register={register('email', { required: true })} errors={errors.email} label='Email' />
            <Button type='submit' color='primary' fullWidth loading={isLoading}>
              Manage My Account
            </Button>
          </form>
        </div>
      </Modal>
      {children && children(() => setModalShown(true))}
    </div>
  )
}
