import 'tailwindcss/tailwind.css'
import '../styles/globals.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import type { AppProps /*, AppContext */ } from 'next/app'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import Context from '../utils/Context'
import JWT from 'jsonwebtoken'
import axios, { AxiosResponse } from 'axios'
import lscache from 'lscache'
import SwiperCore, { Pagination } from 'swiper/core'
import { useRouter } from 'next/router'
import * as ga from '../utils/GA'
import Link from 'next/link'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import ManageDiaperFundModal from '../components/ManageDiaperFundModal'
import type { MeatballJwtClaims } from '../utils/meatballJwt'
import { toastMessage } from '../components/toastMessage'
import { RequestRole } from '../lib/withDiaperBankAuthentication'
import '@datadog/browser-logs/bundle/datadog-logs'
import 'react-loading-skeleton/dist/skeleton.css'

SwiperCore.use([Pagination])

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // TODO: consider disabling retries entirely by default; each query should be forced to make its own decision about retrying
    },
    mutations: {
      // useErrorBoundary: true,
    },
  },
})

export const TOKEN_KEY = 'DB_TOKEN'
const TTL = process.env.NODE_ENV === 'production' ? 30 : 24 * 60

function MyApp({ Component, pageProps }: AppProps) {
  const cachedToken = lscache.get(TOKEN_KEY)
  const [accessToken, setAccessTokenState] = useState<string>(cachedToken)
  const [jwtToken, setJwtToken] = useState<MeatballJwtClaims>(cachedToken && JWT.decode(cachedToken))
  const [mounted, setMounted] = useState<boolean>(false)
  const [role, setRole] = useState<RequestRole>('public')
  const router = useRouter()
  const existingAccessToken = accessToken || lscache.get(TOKEN_KEY)
  const apiClient = axios.create({
    baseURL: '/api/',
    headers: existingAccessToken ? { Authorization: `Bearer ${existingAccessToken}` } : {},
  })
  apiClient.interceptors.response.use(
    function (response: AxiosResponse) {
      return response
    },
    function (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        console.error(`detected unauthorized or forbidden`, error.response.body)
        clearAccessToken()
        toastMessage({ title: 'Not logged in', body: 'Your session is expired.', type: 'error' })
        setShowManageAccount(true)
        router.push('/')
      }
      return Promise.reject(error)
    },
  )
  const [showManageAccount, setShowManageAccount] = useState<boolean>(false)
  useEffect(() => {
    const disableMouseWheelForNumberInput = function (event: any): void {
      if ((document as any)?.activeElement?.type === 'number') {
        ;(document as any).activeElement.blur()
      }
    }
    document.addEventListener('mousewheel', disableMouseWheelForNumberInput)
    if (process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN) {
      window.DD_LOGS.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        service: 'diaper-bank-web',
        sampleRate: 100,
      })
    }
    return () => document.removeEventListener('mousewheel', disableMouseWheelForNumberInput)
  }, [])

  const clearAccessToken = () => {
    setAccessToken('')
    lscache.remove(TOKEN_KEY)
  }

  useEffect(() => setMounted(true), [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // TODO: this doesn't fire on the initial page load, only on route changes, so the route that a user
      // hits first when entering the app won't be recorded as a pageview
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)
    window.scrollTo(0, 0)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const setAccessToken = (token: string) => {
    lscache.set(TOKEN_KEY, token, TTL)
    setAccessTokenState(token)
    const payload = JWT.decode(token)
    if (payload) setJwtToken(payload as MeatballJwtClaims)
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  const customHeadTags = pageProps.customHeadTags as Partial<CustomHeadTags> | undefined

  return (
    <>
      <div className='font-sans leading-normal tracking-normal min-h-screen pt-16 md:bg-gray-200 bg-white'>
        <div className='navbar mb-2 shadow-lg fixed top-0 left-0 w-full z-50 h-12 bg-white'>
          <div className='navbar-start pl-4'>
            {mounted && (
              <div className='dropdown'>
                <div tabIndex={0} className='m-1'>
                  <FontAwesomeIcon icon={faBars} />
                </div>
                <ul tabIndex={0} className='dropdown-content menu'>
                  {accessToken && (
                    <>
                      <li key='account' className='py-4 hover:bg-gray-300'>
                        <button onClick={() => router.push('/banks')}>Account</button>
                      </li>
                      <li key='logout' className='py-4 hover:bg-gray-300'>
                        <button onClick={() => clearAccessToken()}>Logout</button>
                      </li>
                    </>
                  )}
                  {!accessToken && (
                    <li key='logged_on_account'>
                      <button onClick={() => setShowManageAccount(true)}>Manage Account</button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className='navbar-center flex'>
            <div className='flex items-stretch'>
              <Link href='/'>
                <a className='self-start'>
                  <img src='/hb-logo.png' alt='Hello Bello' className='w-12 navbar-logo '></img>
                </a>
              </Link>
            </div>
          </div>
          <div className='navbar-end'></div>
        </div>

        {/* <nav className='bg-white shadow fixed top-0 left-0 w-full z-10 0 h-12'></nav> */}
        <Head>
          <link rel='icon' href='/favicon/favicon.ico' key='icon-favicon' />
          <link rel='apple-touch-icon' sizes='76x76' href='/favicon/apple-touch-icon.png' key='apple-touch-icon' />
          <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' key='icon-32' />
          <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' key='icon-16' />
          <link rel='manifest' href='/favicon/site.webmanifest' key='manifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' key='mask-icon' />
          <meta name='msapplication-TileColor' content='#da532c' key='msapplication-TileColor' />
          <title key='title'>{customHeadTags?.title || 'Hello Bello Diaper Fund'}</title>
          <meta name='description' content={customHeadTags?.description} key='description' />
          <meta property='og:title' content={customHeadTags?.title || 'Hello Bello Diaper Fund'} key='og:title' />
          <meta property='og:description' content={customHeadTags?.ogDescription} key='og:description' />
          <meta property='og:image' content={customHeadTags?.ogImage} key='og:image' />
          <link rel='canonical' href={customHeadTags?.canonicalUrl} key='canonical' />
          <meta property='og:url' content={customHeadTags?.ogUrl} key='og:url' />
          <meta httpEquiv='x-ua-compatible' content='ie=edge' key='x-ua-compatible' />
          <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' key='viewport' />
          <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}></script>
          {process.env.NEXT_PUBLIC_GTM_PROPERTY_ID && <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_PROPERTY_ID}`} />}
          {process.env.NEXT_PUBLIC_GTM_PROPERTY_ID && (
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_PROPERTY_ID}');`,
              }}
            />
          )}
          {process.env.NEXT_PUBLIC_GTM_PROPERTY_ID && (
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GTM_PROPERTY_ID}', {
                  page_path: window.location.pathname,
                });`,
              }}
            />
          )}
        </Head>
        {process.env.NEXT_PUBLIC_GTM_PROPERTY_ID && (
          <>
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_PROPERTY_ID}`}
                height='0'
                width='0'
                style={{
                  display: `none`,
                  visibility: 'hidden',
                }}
              ></iframe>
            </noscript>{' '}
          </>
        )}

        <div className='md:max-w-md m-auto bg-white relative'>
          <Context.Provider value={{ setAccessToken, accessToken, jwtToken, apiClient, queryClient, role, setRole, clearAccessToken }}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer hideProgressBar={true} />
              {showManageAccount && <ManageDiaperFundModal open={true} onClose={() => setShowManageAccount(false)} />}
              {mounted && <Component {...pageProps} />}
              {mounted && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
          </Context.Provider>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MyApp

export interface CustomHeadTags {
  title: string
  ogTitle: string
  description: string
  ogDescription: string
  ogImage: string
  canonicalUrl: string
  ogUrl: string
}
