export default function Footer() {
  return (
    <footer className='bg-lightBlue text-gray-800 font-thin pb-16'>
      <div className='md:max-w-md mx-auto p-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 text-sm'>
        <div className=''>
          <p className='text-center '>
            <a className='underline' href='https://hellobello.com/pages/terms-of-service'>
              Terms of Service
            </a>
            &nbsp; &nbsp; &nbsp;
            <a className='underline' href='https://hellobello.com/pages/privacy-policy  '>
              Privacy Policy
            </a>
          </p>
        </div>
        <div className='mt-2'>
          <p className='text-center'>&copy; Hello Bello {new Date().getFullYear()}. All rights reserved.</p>
        </div>
      </div>
      <div className='bg-lightBlue-dark text-center text-xs p-4'>
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href='https://policies.google.com/privacy' className='underline'>
          Privacy Policy
        </a>{' '}
        and&nbsp;
        <a href='https://policies.google.com/terms' className='underline'>
          Terms of Service
        </a>{' '}
        apply.
      </div>
    </footer>
  )
}
