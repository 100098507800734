// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || process.env.NODE_ENV

Sentry.init({
  dsn: SENTRY_DSN || 'https://fcf74e655a8048f698b6b68d37272923@o226612.ingest.sentry.io/6066963',
  ignoreErrors: ['Non-Error promise rejection captured', 'Illegal invocation', `Can't find variable: PaymentAutofillConfig`],
  enabled: SENTRY_ENVIRONMENT !== 'development',
  autoSessionTracking: false,
  tracesSampleRate: 0.01,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
